import React, {useState, useEffect} from 'react';
import { pages } from './resources';
import './HomePage.css'
import {api} from '../api'
import LogoBDE from '../LogoBDE.js'
import { FaUsers,FaUserPlus,FaImages } from "react-icons/fa";
import { FaFilePen } from "react-icons/fa6";

function HomePage({changeCurrentPage}) {


  const handleSubmit = (event, page) => {
    event.preventDefault();
    changeCurrentPage(page);
  };

  const handleRetourClick = () => {
     window.location.href = '/';
   };

   const handleVigileClick = () => {
    window.location.href = '/vigile';
  };


  return (
    <div className='HomePage-Main'>
      <header className='App-header'>
        <LogoBDE/>
        <h1 className='HomePage-Title'>Admin only</h1>
      </header>
      <div className='HomePage-MainButton-div'>
        <button onClick={(event) => handleSubmit(event, pages.EVENT)} className='HomePage-MainButton' ><FaFilePen alt="event edit" className='svg-qrcode'/>Gérer l'évènement</button>
        <button onClick={(event) => handleSubmit(event, pages.LISTPAGE)} className='HomePage-MainButton'><FaUsers alt="liste" className='svg-qrcode'/>Liste des inscrits</button>
        <button onClick={(event)=>handleSubmit(event, pages.NEWPOSTERPAGE)} className='HomePage-MainButton'><FaImages alt="edit-aboutpage" className='svg-qrcode'/> Gérer la galerie</button>
        <button onClick={(event)=>handleSubmit(event, pages.VOLUNTEERPAGE)} className='HomePage-MainButton'><FaUserPlus alt="add-volunteer" className='svg-qrcode'/> Ajouter un bénévole</button>
      </div>
      <div className='HomePage-Button-div'>
      <button onClick={handleRetourClick} className="HomePage-Button" id="HomePage-retour">Page utilisateur</button>
      <button onClick={handleVigileClick} className="HomePage-Button" id="HomePage-retour">Page vigile</button>
      <button onClick={(event)=>handleSubmit(event, pages.DEBUGPAGE)} className='HomePage-Button'>Outils débug</button>
      </div>
     </div>
  );
}

export default HomePage;

